import React, { useEffect, useState } from 'react';
import { MainWrapper } from './../invoice/mainInvoice/style';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import { Constraint } from './../invoice/mainInvoice/style';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import { InputWrap, BigBtn } from './../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import Alert from './../dashboard/mainContent/fundWallet/alert';
import { ClipLoader } from 'react-spinners';
import EnterPin from './../dashboard/mainContent/fundWallet/enterPin';
import uuid from 'react-uuid';
import CommaNumber from 'comma-number';
import { useNavigate, useParams } from 'react-router';
import { TicketInput } from '../paymentLink/style';
import { roundTo } from 'utils/roundFloat';
import { useSearchParams } from 'react-router-dom';
import { getSign } from 'utils/getCurrencySign';

const MutualWalletTransferComp = () => {
  const navigate = useNavigate();
  const { amount, sourceAmount, point } = useParams();
  const [searchParams] = useSearchParams();
  const currencyPair = searchParams.get('currencyPair');
  const exchangeType = searchParams.get('exchangeType');
  const to = searchParams.get('to');
  const [toCurrency, setToCurrency] = useState<string>('');

  useEffect(() => {
    const to = searchParams.get('to');
    if (to) {
      setToCurrency(to);
    }
  }, [searchParams]);

  let loggedUser: any = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [username, setUsername] = useState('');
  const [fetching, setFetching] = useState(false);
  const [fetchedUser, setFetchedUser] = useState<any>({});
  const [owner, setOwner] = useState<any>({});
  const [nairaBalance, setNairaBalance] = useState<any>('');
  const [dollarBalance, setDollarBalance] = useState<any>('');
  const [poundBalance, setPoundBalance] = useState<string | number>('');
  const [euroBalance, setEuroBalance] = useState<string | number>();
  const [purpose, setPurpose] = useState('');
  const [clientErr, setClientErr] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);
  const [isValid, setIsValid] = useState<boolean | string>(false);

  // Get Balance
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/account-info?`, {
        headers: { Authorization },
      })
      .then(resp => {
        // setUserAccount(resp.data)
        setNairaBalance(resp.data.balance.naira);
        setDollarBalance(resp.data.balance.dollar);
        setPoundBalance(resp.data.balance.pound);
        setEuroBalance(resp.data.balance.euro);
        localStorage.setItem('accountInfo', JSON.stringify(resp.data));
      })
      .catch(err => {
        if (err.response.data.code === 401) {
          localStorage.clear();
          navigate('/login');
        }
      });
  }, [Authorization, navigate]);

  // Get Balance Display
  const getBalance = () => {
    try {
      switch (point) {
        case 'USD':
          return dollarBalance;
        case 'NGN':
          return nairaBalance;
        case 'GBP':
          return poundBalance;
        case 'EUR':
          return euroBalance;
        default:
          break;
      }
    } catch {}
  };

  //   Check Username Validity
  const checkUsername = async (): Promise<any> => {
    try {
      setFetching(true);
      await axios
        .get(
          `${
            process.env.REACT_APP_BASE_ENDPOINT
          }/creator-page/slug/${username.toLowerCase()}?include=owner`,
        )
        .then(res => {
          setIsValid(true);
          setFetching(false);
          setFetchedUser(res.data[0]);
          setOwner(res.data[0].owner);
        })
        .catch(err => {
          setFetching(false);
          if (err.response.data.code === 404) {
            setIsValid('');
          }
        });
    } catch {}
  };

  // Collect Information from Inputs
  const handleInput = (e, setParam, amount: boolean = false) => {
    if (amount) {
      let value = e.target.value;
      if (value.toString().indexOf('0') === 0) value = Number(value).toString();
      setParam(value);
    } else setParam(e.target.value);
  };

  // Payment Parameters
  const paymentParams: any = {
    userId: owner.id,
    purpose: purpose,
    idempotentKey: uuid(),
    targetAmount: `${amount}`,
    sourceAmount: sourceAmount,
    sourceCurrency: point,
    targetCurrency: to,
  };

  // Enter Pin for transaction

  // Get User Data from Database
  const [pin, setPin] = useState<any>('');

  // Set Pin
  const handlePinChange = pin => {
    setPin(pin);
  };

  const [pinModal, setPinModal] = useState(false);

  const handleClosePinModal = () => {
    setPinModal(false);
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  // After Confirm Pin
  const [result, setResult] = useState<boolean | string>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const closeMessage = () => {
    setResult(false);
  };

  const [finalOnProcess, setFinalOnProcess] = useState(false);
  const handleFinalPay = () => {
    setFinalOnProcess(true);

    if (currencyPair !== 'null') {
      paymentParams['currencyPair'] = currencyPair;
    }

    if (exchangeType !== 'null') {
      paymentParams['exchangeType'] = exchangeType;
    }

    if (pin) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/auth/confirm-pin`,
          {
            pin,
            userId: loggedUser.user.id,
          },
          headers,
        )
        .then(res => {
          if (res.data.status) {
            setFinalOnProcess(false);
            setPin('');
            axios
              .post(
                `${process.env.REACT_APP_BASE_ENDPOINT}/payments/transfer`,
                paymentParams,
                headers,
              )
              .then(res => {
                setFinalOnProcess(false);
                setSuccess(true);
                localStorage.removeItem('selectedFreqBen');
              })
              .catch(err => {
                setFinalOnProcess(false);
                setError(err.response.data.message);
                setTimeout(() => {
                  setError('');
                }, 4000);
              });
          } else {
            setFinalOnProcess(false);
            setErrorMessage(`We couldn't confirm your email at the moment.`);
            setPin('');
            setTimeout(() => {
              setErrorMessage('');
            }, 4000);
          }
        })
        .catch(err => {
          setFinalOnProcess(false);
          setErrorMessage(err.response.data.message);
          setPin('');
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    }
  };

  const handlePayment = async () => {
    setOnProcess(true);
    if (Number(amount) > 0) {
      if (Number(sourceAmount) && Number(sourceAmount) <= getBalance()) {
        if (fetchedUser.createdBy) {
          if (loggedUser.user.pinSetup) {
            setPinModal(true);
            setOnProcess(false);
          } else {
            setError(
              "You can't transfer funds until you set up a pin for your account",
            );
          }
        } else {
          setClientErr('Please select a split!');
          setOnProcess(false);
          setTimeout(() => {
            setClientErr('');
          }, 2000);
        }
      } else {
        setOnProcess(false);
        setClientErr(
          'You do not have sufficient balance to make this transaction',
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    } else {
      setOnProcess(false);
      setClientErr(`Amount can't be empty and must be greater than 0`);
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  useEffect(() => {
    if (pin.length === 4) handleFinalPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const closeModal = () => {
    setError('');
  };

  const closeSuccess = () => {
    setSuccess(false);
    setPinModal(false);
    navigate('/');
  };

  return (
    <>
      <MainWrapper
        width="100%"
        style={{
          background: '#fff',
        }}
      >
        <FragmentHeader showBack={true} pageName="Send Money (Mage User)" />
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              top="-2rem"
              mobileTop="-2rem"
              style={{ margin: '0 auto' }}
            >
              <div>
                <InputWrap>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label>
                      Amount - {getSign(point)}
                      {CommaNumber(sourceAmount)}
                    </label>
                    <p
                      style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '16px',
                        letterSpacing: '-0.02em',
                        color: '#ABABAB',
                      }}
                    >
                      Balance: {getSign(point)}
                      {CommaNumber(roundTo(getBalance(), 2))}
                    </p>
                  </div>
                </InputWrap>
                <TicketInput hasLabel={true}>
                  <div>
                    <p>Username</p>
                    <label>@</label>
                    <input
                      minLength={3}
                      required={true}
                      onChange={e => setUsername(e.target.value)}
                      onBlur={() => checkUsername()}
                    />
                  </div>
                </TicketInput>
                {fetching ? (
                  <div
                    style={{
                      margin: '2rem 0',
                    }}
                  >
                    <ClipLoader size={25} />
                  </div>
                ) : null}
                {username && isValid ? (
                  <p
                    style={{
                      color: '#c8382b',
                      fontSize: '14x',
                      marginTop: '-10px',
                    }}
                  >
                    {owner.firstName} {owner.lastName}
                  </p>
                ) : null}
                {username && isValid === '' ? (
                  <p
                    style={{
                      color: '#c8382b',
                      fontSize: '13px',
                      marginTop: '-10px',
                    }}
                  >
                    Username is not valid
                  </p>
                ) : null}
                <InputWrap>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label>Purpose</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Purpose"
                    onChange={e => handleInput(e, setPurpose)}
                  />
                </InputWrap>
              </div>
              <p style={{ width: '20rem', fontSize: '0.8rem', color: 'red' }}>
                {clientErr}
              </p>
              <BigBtn>
                <button
                  onClick={e => handlePayment()}
                  disabled={
                    onProcess || !isValid || isValid === '' ? true : false
                  }
                >
                  {onProcess ? <ClipLoader color="#fff" /> : 'Send Money'}
                </button>
              </BigBtn>
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>
      </MainWrapper>

      {pinModal && !success ? (
        <EnterPin
          closeFunc={handleClosePinModal}
          prevFunc={handleClosePinModal}
          propPin={pin}
          errorMessage={errorMessage}
          handlePinChange={handlePinChange}
          handleFinalPay={handleFinalPay}
          onProcess={finalOnProcess}
          bank={'PAGA'}
          accountName={`${fetchedUser.owner.firstName} ${fetchedUser.owner.firstName}`}
          accountNo={''}
          amount={amount}
          mode="merchro"
          currency={toCurrency}
        />
      ) : null}

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="Transaction Unsuccessful!!!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeSuccess}
          img="/success.png"
          message="Transaction successful"
          miniMessage={'Funds are on the way to the recipient '}
        />
      ) : null}

      {result === '' ? (
        <Alert
          closeFunc={closeMessage}
          img="/failed.png"
          message="Changes not successful!"
          miniMessage="Encountered an error while creating your transaction PIN"
        />
      ) : null}
    </>
  );
};

export default MutualWalletTransferComp;
