import { useCallback } from 'react';
import * as Icon from 'react-feather';
import * as IconSax from 'iconsax-react';
import {
  MainArea,
  MainFlex,
  LeftWidget,
  RightWidget,
  CardFlex,
  SubHeaderWrap,
  RightCards,
  HistoryItemIconWrap,
  HistoryItemCard,
  HistoryTextWrap,
  HistoryAmount,
  HistoryWidget,
  HistoryInfoSec,
  HeadFlex,
  GreetingWrap,
  QuickActionWrap,
  QuickActionItemWrap,
  DisclaimerSection,
  ArtistCard,
  Logohandler,
  Texthandler,
  WrapperArtistCard,
  QuickActionItems,
  QuickActionItemsCard,
  QuickItemLogo,
  QuicktextWrapper,
  // AdPart,
  // BackGroundImageSlideShow,
} from './style';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FundWallet from './fundWallet/fundWallet';
import BankTransfer from './fundWallet/bankTransfer';
import PaymentMethod from './fundWallet/paymentMet';
import BuyAirtime from './fundWallet/buyAirtime';
import TransferFinal from './fundWallet/transferFinal';
import PayBill from './fundWallet/payBill';
import BillTransferOption from './fundWallet/billtransferOption';
import moment from 'moment';
import { handleBg } from 'utils/handleTransIcon';
import 'react-multi-carousel/lib/styles.css';
import CommaNumber from 'comma-number';
import Details from 'app/components/transactions/details';
import DashboardHeader from './dashboardHeader';
import TransferOption from 'app/components/transactions/transferOption';
import DollarBankTransfer from 'app/components/transactions/dollarBankTransfer';
import Blocked from 'app/components/transactions/blocked';
import { getSign } from 'utils/getCurrencySign';
import NewWalletCard from './newWalletCard';
import { getRequest } from 'utils/request';
import Store from 'utils/store';

const SubMain = () => {
  const width = window.innerWidth;
  // Modals States and Functions

  const [activeIndex, setActiveIndex] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [showTransferOption, setShowTransferOption] = useState(false);
  const [showFundWallet, setShowFundWallet] = useState<any>(false);
  const [showPaymentMet, setShowPaymentMet] = useState<any>(false);
  const [showBank, setShowBank] = useState<any>(false);
  const [showTransferFinal, setShowTransferFinal] = useState<any>(false);
  const [showPayBill, setShowPayBill] = useState<any>(false);
  const [showBillTransferOption, setShowBillTransferOption] =
    useState<any>(false);
  const [showAirtime, setShowAirtime] = useState<any>(false);
  const [showDollarBankTransfer, setShowDollarBankTransfer] = useState(false);
  const [tier, setTier] = useState(
    Store.get('cky') ? Store.get('cky').level : '',
  );

  const [bvnKycStatus, setBvnKycStatus] = useState<any>(
    Store.get('cky') ? Store.get('cky').bvn : {},
  );
  const [idKycStatus, setIdKycStatus] = useState<any>(
    Store.get('cky') ? Store.get('cky').document : {},
  );

  // Handle Account Information
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountBank, setAccountBank] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');
  const [nairaBalance, setNairaBalance] = useState<string | number>('');
  const [dollarBalance, setDollarBalance] = useState<string | number>('');
  const [poundBalance, setPoundBalance] = useState<string | number>('');
  const [euroBalance, setEuroBalance] = useState<string | number>();
  const [amount, setAmount] = useState(0);
  const [amountErr, setAmountErr] = useState('');

  const [selected, setSelected] = useState<any>({});
  const [detailsModal, setDetailsModal] = useState(false);
  const [totalSplit, setTotalSplit] = useState<any>(0);
  const [totalInvoice, setTotalInvoice] = useState<any>(0);
  const [totalDeals, setTotalDeals] = useState<{
    active: number;
    funded: number;
    declined: number;
  }>({
    active: 0,
    funded: 0,
    declined: 0,
  });
  // Toggle Balance
  const [isShowing, setIsShowing] = useState(false);
  const [isDollarShowing, setIsDollarShowing] = useState(false);
  const [isPoundShowing, setIsPoundShowing] = useState(false);
  const [isEuroShowing, setIsEuroShowing] = useState(false);
  const navigate = useNavigate();
  const [history, setHistory] = useState<any>([]);

  let loggedUser: any = Store.get('logged_user') || {};
  if (Object.keys(loggedUser).length === 0) {
    localStorage.clear();
    window.location.href = '/login';
  }
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const getBalance = useCallback(() => {
    if (tier && tier.toLowerCase() === 'tier 3') {
      getRequest(`payments/account-info?`)
        .then(resp => {
          // setUserAccount(resp.data)
          setNairaBalance(resp.balance.naira);
          setDollarBalance(resp.balance.dollar);
          setEuroBalance(resp.balance.euro);
          setPoundBalance(resp.balance.pound);
          setAccountNumber(resp.accountInfo.accountNumber);
          setAccountBank(resp.accountInfo.bankName);
          setAccountName(resp.accountInfo.accountName);
          Store.create('accountInfo', resp);
        })
        .catch(err => {});
    }
  }, [tier]);
  useEffect(() => {
    if (!tier) {
      getRequest('users/kyc-status')
        .then(res => {
          setBvnKycStatus(res.bvn);
          setIdKycStatus(res.document);
          setTier(res.level);
          Store.create('cky', res);
        })
        .catch(err => {});
      if (tier.toLowerCase() === 'tier 3') {
        getBalance();
      }
    } else {
      getBalance();
    }
  }, [Authorization, getBalance, tier]);

  function toggleFundWallet() {
    if (
      bvnKycStatus.status.toLowerCase() === 'verified' &&
      idKycStatus.status.toLowerCase() === 'verified'
    ) {
      setShowFundWallet(true);
      setShowBank(false);
      setShowPaymentMet(false);
    } else {
      setBlocked(true);
    }
  }
  function toggleBank() {
    if (
      bvnKycStatus.status.toLowerCase() === 'verified' &&
      idKycStatus.status.toLowerCase() === 'verified'
    ) {
      setShowBank(true);
      setShowPaymentMet(false);
      setShowFundWallet(false);
    } else {
      setBlocked(true);
    }
  }

  function toggleDollarBankData() {
    setShowDollarBankTransfer(false);
  }

  function togglePayBill() {
    setShowPayBill(true);
    setShowBillTransferOption(false);
  }
  function toggleBillOption() {
    setShowBillTransferOption(true);
    setShowPayBill(false);
  }

  function closeFundWallet() {
    setShowTransferFinal(false);
    setShowBank(false);
    setShowPaymentMet(false);
    setShowFundWallet(false);
    setShowAirtime(false);
    setShowPayBill(false);
    setShowBillTransferOption(false);
    setAmount(0);
    setAmountErr('');
    localStorage.removeItem('selectedFreqBen');
    getAccountInfo();
    getTransactions();
    getBalance();
  }

  const toggleDetailsModal = item => {
    setDetailsModal(!detailsModal);
    setSelected(item);
  };

  useEffect(() => {
    if ('creatorPage' in loggedUser.user) {
      return;
    } else {
      navigate('/create-pin');
    }
  }, [navigate, loggedUser.user]);

  const getAccountInfo = useCallback(() => {
    const myStore = Store.get('store') || {};

    if (Object.keys(myStore).length > 0) {
    } else {
      getRequest(`creator-page/${loggedUser.user.creatorPage}`).then(resp => {
        Store.create('store', resp);
      });
    }
  }, [loggedUser.user.creatorPage]);

  // Transaction History
  const getTransactions = useCallback(() => {
    getRequest(`payments/transactions?include=user&sortBy=createdAt:desc`)
      .then(res => {
        setHistory(res.results);
      })
      .catch(err => {});
  }, []);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const handleToggleClick = () => {
    setIsShowing(!isShowing);
  };
  const handleDollarToggleClick = () => {
    setIsDollarShowing(!isDollarShowing);
  };
  const handlePoundToggleClick = () => {
    setIsPoundShowing(!isPoundShowing);
  };
  const handleEuroToggleClick = () => {
    setIsEuroShowing(!isEuroShowing);
  };

  // Available Currencies and Data
  const wallets = [
    {
      name: 'NGN',
      tag: 'NG',
      balance: nairaBalance,
      showBankAction: toggleBank,
      isShowing: isShowing,
      toggleFunc: handleToggleClick,
    },
    {
      name: 'USD',
      tag: 'US',
      balance: dollarBalance,
      showBankAction: () => toggleDollarBankData,
      isShowing: isDollarShowing,
      toggleFunc: handleDollarToggleClick,
    },
    {
      name: 'GBP',
      tag: 'GB',
      balance: poundBalance,
      showBankAction: () => {},
      isShowing: isPoundShowing,
      toggleFunc: handlePoundToggleClick,
    },
    {
      name: 'EUR',
      tag: 'EU',
      balance: euroBalance,
      showBankAction: () => {},
      isShowing: isEuroShowing,
      toggleFunc: handleEuroToggleClick,
    },
  ];

  const handleRedirect = (to: string, kyc: boolean = false) => {
    if (kyc && tier.toLowerCase() !== 'tier 3') {
      setBlocked(true);
      return;
    }
    navigate(`/${to}`);
  };

  useEffect(() => {
    getRequest(`splits`)
      .then(res => {
        setTotalSplit(res.totalResults);
      })
      .catch(err => {});

    getRequest(`invoices`)
      .then(res => {
        setTotalInvoice(res.totalResults);
      })
      .catch(err => {});
    getRequest(`deals?paginate=false`).then(res => {
      const deal = { active: 0, funded: 0, declined: 0 };
      deal.active = res.filter(d => d.status === 'pending').length;
      deal.declined = res.filter(d => d.status === 'declined').length;
      getRequest(`deals?claimer=${loggedUser.user.id}&paginate=false`).then(
        res => {
          deal.funded = res.length;
        },
      );
      setTotalDeals(deal);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser.user.id]);

  const quickActions = [
    {
      name: 'Split',
      bgColor: '#ffc2272b',
      icon: () => {
        return (
          <IconSax.Arrow
            size={30}
            strokeWidth="2px"
            className="fas fa-home"
            color="#FFC327"
          />
        );
      },
      count: totalSplit,
      redirect: 'split-payment',
    },
    {
      name: 'Invoices',
      bgColor: '#3cfb5928',
      icon: () => {
        return (
          <IconSax.Note1
            size={30}
            strokeWidth="2px"
            className="fas fa-home"
            color="#00f224"
          />
        );
      },
      count: totalInvoice,
      redirect: 'invoice',
    },
    {
      name: 'Credit',
      bgColor: '#ff672628',
      icon: () => {
        return (
          <Icon.CreditCard
            size={30}
            strokeWidth="2px"
            className="fas fa-home"
            color="#e14400"
          />
        );
      },
      count: 0,
      redirect: '',
    },
  ];

  const quickActionsFI = [
    {
      name: 'Active Deals',
      bgColor: '#ffc2272b',
      icon: () => {
        return (
          <IconSax.Paintbucket
            size={30}
            strokeWidth="2px"
            className="fas fa-home"
            color="#FFC327"
          />
        );
      },
      count: totalDeals.active,
      redirect: 'deals',
    },
    {
      name: 'Funded',
      bgColor: '#3cfb5928',
      icon: () => {
        return (
          <IconSax.Briefcase
            size={30}
            strokeWidth="2px"
            className="fas fa-home"
            color="#00f224"
          />
        );
      },
      count: totalDeals.funded,
      redirect: 'my-deals',
    },
    {
      name: 'Declined',
      bgColor: '#ff672628',
      icon: () => {
        return (
          <IconSax.CloseSquare
            size={30}
            strokeWidth="2px"
            className="fas fa-home"
            color="#e14400"
          />
        );
      },
      count: totalDeals.declined,
      redirect: 'my-deals',
    },
  ];

  const activeQuickActionItems = loggedUser.user.accessDeals
    ? quickActionsFI
    : quickActions;

  const quickAction2 = [
    {
      title: 'Send Money',
      icon: <Icon.DollarSign color="#007bff" />,
      action: () => handleRedirect('select-currency', true),
      customBg: 'linear-gradient(#6ab0fb, #007AFF)',
      customBg2: '',
      background: '#007bff68',
    },
    {
      title: 'Fund Wallet',
      icon: <IconSax.Wallet color="#ffb700" />,
      action: () => toggleBank(),
      customBg: 'linear-gradient(#f0ea79, #ffb700)',
      background: '#ffb70068',
    },
    {
      title: 'Create Split',
      icon: <IconSax.Arrow color="#ff6600" />,
      action: () => handleRedirect('select-split-type'),
      customBg: 'linear-gradient(#f39c9c, #ff6600)',
      background: '#ff660068',
    },
    {
      title: 'Create Invoice',
      icon: <IconSax.Note1 color="#46AA09" />,
      action: () => handleRedirect('setup-invoice'),
      customBg: 'linear-gradient(#31f74f, #46AA09)',
      background: '#46AA0968',
    },
  ];

  if (loggedUser.user.accessDeals) {
    quickAction2[2] = {
      title: 'Browse Deals',
      icon: <IconSax.Paintbucket color="#ff6600" />,
      action: () => handleRedirect('deals'),
      customBg: 'linear-gradient(#f39c9c, #ff6600)',
      background: '#ff660068',
    };
    quickAction2[3] = {
      title: 'View My Deals',
      icon: <IconSax.Briefcase color="#46AA09" />,
      action: () => handleRedirect('my-deals'),
      customBg: 'linear-gradient(#31f74f, #46AA09)',
      background: '#46AA0968',
    };
  }

  return (
    <>
      <DashboardHeader />
      <HeadFlex></HeadFlex>
      <MainArea className="no-scrollbar">
        <MainFlex className="no-scrollbar">
          <LeftWidget>
            {width < 728 ? (
              <GreetingWrap
                style={{
                  padding: '3rem 0 0 0',
                }}
              >
                <h1>Hi, {loggedUser.user.firstName}</h1>
              </GreetingWrap>
            ) : null}
            {width > 728 ? (
              <GreetingWrap
                style={{
                  padding: '2rem 0 0 0',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <h1>Hi, {loggedUser.user.firstName}</h1>
              </GreetingWrap>
            ) : null}
            <CardFlex className="no-scrollbar">
              <div>
                <WrapperArtistCard>
                  {activeQuickActionItems.map((item, index) => {
                    return (
                      <ArtistCard
                        key={index}
                        onClick={() => handleRedirect(item.redirect)}
                      >
                        <Logohandler customBg={item.bgColor}>
                          {item.icon()}
                        </Logohandler>
                        <Texthandler>
                          <h3>{item.count}</h3>
                          <p>{item.name}</p>
                        </Texthandler>
                      </ArtistCard>
                    );
                  })}
                </WrapperArtistCard>
              </div>
            </CardFlex>
            <QuickActionWrap>
              <GreetingWrap
                style={{
                  position: 'relative',
                  top: '0.7rem',
                }}
              >
                {width > 768 ? <div style={{ marginTop: 100 }}></div> : null}
                <h1>Quick Actions</h1>
              </GreetingWrap>

              <QuickActionItemWrap>
                <QuickActionItems className="no-scrollbar">
                  {quickAction2.map((item, index) => {
                    return (
                      <QuickActionItemsCard
                        onClick={item.action}
                        customBg={item.customBg}
                      >
                        <QuickItemLogo
                          customBg={''}
                          style={{
                            backgroundPositionX: '-19px',
                            backgroundPositionY: '-31px',
                            backgroundSize: '200px',
                            background: item.background,
                          }}
                        >
                          {item.icon}
                        </QuickItemLogo>
                        <QuicktextWrapper
                          style={{
                            height: '50px',
                          }}
                        >
                          <h3>{item.title}</h3>
                        </QuicktextWrapper>
                        <QuicktextWrapper>
                          <p>Get started</p>
                          <Icon.ArrowRight
                            color="white"
                            size={14}
                            strokeWidth={5}
                          />
                        </QuicktextWrapper>
                      </QuickActionItemsCard>
                    );
                  })}
                </QuickActionItems>
              </QuickActionItemWrap>
              {width > 768 ? <div style={{ marginTop: 163 }}></div> : null}
            </QuickActionWrap>
            {/* 
            {width > 728 ? (
              <AdPart>
                <BackGroundImageSlideShow>
                  <p>{'// OPPORTUNITIES'}</p>
                  <h3>Submit your Beats and Songs</h3>
                  <h3>For A Chance To Get Placements</h3>
                </BackGroundImageSlideShow>
              </AdPart>
            ) : null} */}
          </LeftWidget>
          <RightWidget>
            <>
              {wallets.slice(0, 2).map((item, index) => (
                <NewWalletCard
                  currencyTag={item.tag}
                  currencyName={item.name}
                  isShowing={item.isShowing}
                  index={index}
                  key={index}
                  setActiveIndex={setActiveIndex}
                  activeIndex={activeIndex}
                  balance={item.balance}
                  handleToggle={item.toggleFunc}
                  toggleBank={item.showBankAction}
                />
              ))}
            </>
            <RightCards padding="7% 0 7% 0">
              <HistoryWidget>
                <SubHeaderWrap>
                  <h3>Recent Transactions</h3>
                  <p
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={e => {
                      navigate('/transactions');
                      localStorage.setItem('activeTag', JSON.stringify('NG'));
                    }}
                  >
                    See all
                  </p>
                </SubHeaderWrap>

                {history && history.length > 0 ? (
                  <>
                    {history.map((item, index) => (
                      <HistoryItemCard
                        onClick={() => toggleDetailsModal(item)}
                        key={index}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <HistoryItemIconWrap
                          customBg={() =>
                            handleBg(item.type.toLocaleLowerCase(), item.source)
                          }
                        >
                          {item.type === 'debit' ? (
                            <Icon.ArrowUpRight color="rgba(200, 43, 50, 1)" />
                          ) : (
                            <>
                              {item.source === 'exchange' ? (
                                <Icon.Repeat color="#8975F3" />
                              ) : (
                                <Icon.ArrowDownLeft color="rgba(107, 211, 43, 1)" />
                              )}
                            </>
                          )}
                        </HistoryItemIconWrap>
                        <HistoryTextWrap>
                          {item.meta.payerName !== undefined ? (
                            <h4>
                              {item.meta.payerName
                                ? item.meta.payerName
                                : 'No name attached'}
                            </h4>
                          ) : (
                            <h4>
                              {item.meta.accountName
                                ? item.meta.accountName
                                : 'No name attached'}
                            </h4>
                          )}

                          <p>{moment(item.updatedAt).format('LL')}</p>
                        </HistoryTextWrap>
                        <HistoryInfoSec>
                          <HistoryAmount>
                            {getSign(item.currency)}
                            {CommaNumber(item.amount)}
                          </HistoryAmount>
                        </HistoryInfoSec>
                      </HistoryItemCard>
                    ))}
                  </>
                ) : (
                  <img
                    style={{
                      textAlign: 'center',
                      margin: '2rem auto 0 auto',
                      width: '98%',
                      display: 'block',
                    }}
                    src="/transaction.png"
                    alt="No Transaction yet"
                  />
                )}
              </HistoryWidget>
            </RightCards>
          </RightWidget>
        </MainFlex>
        <DisclaimerSection mobileShow={true}>
          <p>
            Mage is a financial technology company and is not a bank. Banking
            services provided by Kuda Microfinance Bank and PAGA; Deposits
            insured by NDIC. Payments services provided by Flutterwave.
          </p>
        </DisclaimerSection>
        {/* {width < 728 ? (
          <AdPart>
            <BackGroundImageSlideShow>
              <p>{'// OPPORTUNITIES'}</p>
              <h3>Submit your Beats and Songs</h3>
              <h3>For A Chance To Get Placements</h3>
            </BackGroundImageSlideShow>
          </AdPart>
        ) : null} */}

        {showTransferOption ? (
          <TransferOption closeFunc={() => setShowTransferOption(false)} />
        ) : null}

        {detailsModal ? (
          <Details props={selected} closeFunc={toggleDetailsModal} />
        ) : null}

        {/* Wallets Modals */}
        {showDollarBankTransfer ? (
          <DollarBankTransfer
            closeFunc={() => setShowDollarBankTransfer(false)}
            accountNo=""
          />
        ) : null}
        {showFundWallet ? (
          <FundWallet
            amountErr={amountErr}
            closeFunc={closeFundWallet}
            openNext={toggleBank}
            setAmount={setAmount}
            amount={amount}
          />
        ) : null}
        {showPaymentMet ? (
          <PaymentMethod
            amount={amount}
            closeFunc={closeFundWallet}
            otherOption={toggleBank}
            prevFunc={toggleFundWallet}
          />
        ) : null}
        {showBank ? (
          <BankTransfer
            closeFunc={closeFundWallet}
            accountName={accountName}
            accountNo={accountNumber}
            bankName={accountBank}
          />
        ) : null}
        {showTransferFinal ? (
          <TransferFinal
            closeFunc={closeFundWallet}
            openSide={() => {}}
            balance={nairaBalance}
          />
        ) : null}
        {showAirtime ? (
          <BuyAirtime closeFunc={closeFundWallet} balance={nairaBalance} />
        ) : null}
        {showPayBill ? (
          <PayBill closeFunc={closeFundWallet} openNext={toggleBillOption} />
        ) : null}
        {showBillTransferOption ? (
          <BillTransferOption
            closeFunc={closeFundWallet}
            prevFunc={togglePayBill}
          />
        ) : null}
        {blocked ? <Blocked closeFunc={() => setBlocked(false)} /> : null}
      </MainArea>
    </>
  );
};

export default SubMain;
