import { FC, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { setBooleanStateType } from 'utils/interfaces/setStateType';
import './index.css';
import {
  MainSideWrapper,
  // SideItemDesc,
  SideItemText,
  SideNavItemCard,
  SideNavWrapper,
  // SubItem,
  TopDiv,
} from './style';
import * as Icon from 'react-feather';
import * as IconSax from 'iconsax-react';
import Warning from 'reusable/warning';
import {
  PaddedArea,
  FlexedApart,
  UserAvatar,
  //   UserTexts,
} from 'app/components/storeComp/sideNav/style';
import { Link } from 'react-router-dom';
import { HeaderPopUp } from '../mainContent/header/styles';
import Store from 'utils/store';

interface NavProps {
  mobileShow: string;
  setNavbarOpen?: setBooleanStateType;
}

const SideNav: FC<NavProps> = (props): JSX.Element => {
  const history = useNavigate();
  const location = useLocation();
  const current = location.pathname;
  let logged_user = Store.get('logged_user');

  // Log out Functions

  const [showPopUp, setShowPopUp] = useState(false);
  const [warning, setWarning] = useState(false);

  const toggleWarning = () => {
    setWarning(!warning);
  };
  const handleLogout = () => {
    localStorage.clear();
    history('/login');
  };

  const overviewLinks = ['/'];
  const overviewActive = overviewLinks.includes(current);
  const cardLinks = ['/request-card', '/virtual-card'];
  // const customerLinks = ['/customers'];
  const splitLinks = ['/split-payment'];
  // const songsLinks = ['/songs'];
  // const artisteLink = ['/artiste'];
  // const distributorsLink = ['/distributor'];
  // const myStoreLinks = [
  //   '/store',
  //   '/order',
  //   '/product-list',
  //   '/update-store',
  //   '/add-merch',
  //   '/edit-merch',
  // ];
  const invoiceLinks = [
    '/invoice',
    '/setup-invoice',
    '/create-invoice',
    '/new-invoice',
    '/documents',
  ];
  // const eventLinks = ['/events', '/single-event'];
  const transactionsLinks = ['/transactions'];
  // const paymentLinks = [
  //   '/payment-link',
  //   '/create-payment-link',
  //   '/select-payment-type',
  // ];
  // const bookingsLinks = ['/bookings'];

  const mainNavs = [
    {
      name: 'Overview',
      link: '/',
      activeState: overviewActive,
      icon: () => <Icon.Home size={20} strokeWidth="1px" />,
    },
    {
      name: 'Transactions',
      link: '/transactions',
      activeState: transactionsLinks.includes(current),
      icon: () => <Icon.DollarSign size={20} strokeWidth="1px" />,
    },
    // {
    //   name: 'Songs',
    //   link: '/songs',
    //   activeState: songsLinks.includes(current),
    //   icon: () => <Icon.DollarSign size={20} strokeWidth="1px" />,
    // },
    {
      name: 'Cards',
      link: '/virtual-cards',
      activeState: cardLinks.includes(current),
      icon: () => <Icon.CreditCard size={20} strokeWidth="1px" />,
    },
  ];

  const dealAccessSidebarData = [
    {
      name: 'Deals',
      link: '/deals',
      activeState: current === '/deals',
      icon: () => <IconSax.Paintbucket size={20} strokeWidth="1px" />,
    },
    {
      name: 'My Deals',
      link: '/my-deals',
      activeState: current === '/my-deals',
      icon: () => <IconSax.Briefcase size={20} strokeWidth="1px" />,
    },
  ];
  const noDealAccessSidebarData = [
    {
      name: 'Invoice',
      link: '/invoice',
      activeState: invoiceLinks.includes(current),
      icon: () => <IconSax.Note1 size={20} strokeWidth="1px" />,
    },
    {
      name: 'Splits',
      link: '/split-payment',
      activeState: splitLinks.includes(current),
      icon: () => <IconSax.Arrow size={20} strokeWidth="1px" />,
    },
  ];

  if (logged_user.user.accessDeals) {
    dealAccessSidebarData.forEach((nav: any) => {
      mainNavs.push(nav);
    });
  } else {
    noDealAccessSidebarData.forEach((nav: any) => {
      mainNavs.push(nav);
    });
  }

  return (
    <>
      <MainSideWrapper
        mobileShow={props.mobileShow}
        onClick={e => {
          e.stopPropagation();
          setShowPopUp(false);
        }}
      >
        <CloseButton
          onClick={() =>
            props.setNavbarOpen ? props.setNavbarOpen(false) : {}
          }
        ></CloseButton>

        <SideNavWrapper>
          {mainNavs.map((item, index) => {
            return (
              <NavLink
                to={item.link}
                className="active"
                key={index}
                onClick={() => {
                  if (item.name === 'Transactions')
                    localStorage.setItem('activeTag', JSON.stringify('NG'));
                }}
              >
                <SideNavItemCard
                  className={item.activeState ? 'active' : 'normal-text'}
                >
                  {item.icon()}
                  <SideItemText>{item.name}</SideItemText>
                </SideNavItemCard>
              </NavLink>
            );
          })}

          {/* <SideItemDesc>CONTENT</SideItemDesc> */}
          {/* Songs will be re-introduced later */}
          {/* <NavLink to="/songs" className="active">
            <SideNavItemCard className={songsActive ? 'active' : 'normal-text'}>
              <Icon.Music
                size={20}
                strokeWidth="1px"
                className="fas fa-cart-arrow-down"
              />
              <SideItemText>Songs</SideItemText>
            </SideNavItemCard>
          </NavLink> */}
        </SideNavWrapper>
        <TopDiv>
          <FlexedApart
            style={{
              justifyContent: 'flex-start',
              gap: '20px',
              position: 'absolute',
              top: -60,
            }}
          >
            <PaddedArea>
              <FlexedApart
                onClick={e => {
                  e.stopPropagation();
                  setShowPopUp(!showPopUp);
                }}
              >
                {logged_user.user && logged_user.user.avatar ? (
                  <UserAvatar>
                    <img
                      src={
                        logged_user.user.avatar
                          ? logged_user.user.avatar
                          : '/test-avatar.png'
                      }
                      alt={logged_user.user.avatar}
                    />
                  </UserAvatar>
                ) : (
                  <UserAvatar
                    style={{
                      background: '#fff',
                      border: '2px solid #dadada',
                      color: '#191919',
                    }}
                  >
                    <h4>
                      {logged_user.user
                        ? logged_user.user.firstName.split('')[0]
                        : ''}
                    </h4>
                    <h4>
                      {logged_user.user
                        ? logged_user.user.lastName.split('')[0]
                        : ''}
                    </h4>
                  </UserAvatar>
                )}
              </FlexedApart>
              {/* </Link> */}
            </PaddedArea>
          </FlexedApart>
        </TopDiv>
        {showPopUp ? (
          <HeaderPopUp>
            {/* <a
              href={`https://${myStore.slug}${process.env.REACT_APP_PAGE_LINK}?token=${logged_user.tokens.access.token}`}
              target="_blank"
              rel="noreferrer"
            >
              <p
                style={{
                  fontWeight: 400,
                  border: '0.2px solid rgba(0, 0, 0, 0.1)',
                  padding: '0.2rem',
                  borderRadius: '5px',
                }}
              >
                {myStore.slug}
                {process.env.REACT_APP_PAGE_LINK}
              </p>
            </a> */}
            {/* <p>{logged_user.user ? logged_user.user.email : ''}</p> */}
            <Link to="/profile">
              <p>
                <Icon.User color="#444444" size={16} />
                Account Details
              </p>
            </Link>
            <Link to="/contact">
              <p>
                <Icon.HelpCircle color="#444444" size={16} /> Help & Support
              </p>
            </Link>
            <p onClick={() => toggleWarning()}>
              <Icon.LogOut color="#444444" size={16} />
              Log Out
            </p>
          </HeaderPopUp>
        ) : null}
      </MainSideWrapper>

      {warning ? (
        <Warning
          closeFunc={toggleWarning}
          productForDelete={''}
          handleDelete={handleLogout}
          header="Sign Out"
          deleteInProcess={false}
          message="Do you want to sign out from your account?"
          actionText="Sign out"
        />
      ) : null}
    </>
  );
};

export default SideNav;

const CloseButton = styled.div`
  /* width: 2rem;
  height: 2rem; */
  display: flex;
  align-items: Center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 15px;
  border-radius: 10rem;
  display: none;

  > i {
    color: #191919;
    font-size: 20px;
  }

  @media (max-width: 1028px) {
    display: flex;
    display: none;
  }
`;
