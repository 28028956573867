import styled from 'styled-components';

interface MainWrapperProps {
  bg?: string;
  left?: string;
  padding?: string;
  top?: string;
  mobileTop?: string;
}

export const MainWrapper = styled.div<MainWrapperProps>`
  width: 83%;
  padding: ${p => p.padding || '0 3% 4rem 3%'};
  position: relative;
  left: ${p => p.left || '15%'};
  top: ${p => p.top || '5.5rem'};
  border-radius: 5px;
  background: ${p => p.bg || '#fbfbfb'};
  min-height: 90vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1028px) {
    background: #fff;
    width: 100%;
    padding: 2.5rem 4%;
    top: ${p => p.mobileTop || '0'};
    left: 0;
    margin: 0;
  }
`;

export const InAppHeader = styled.div<MainWrapperProps>`
  position: absolute;
  top: 2rem;
  left: 3.5%;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;

  > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #191919;

    @media (max-width: 728px) {
      font-size: 18px;
    }
  }

  i {
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 728px) {
    display: none;
    position: relative;
    top: ${p => p.mobileTop || '4rem'};
    left: 0;
  }
`;
