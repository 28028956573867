import React, { useState, useEffect } from 'react';
import AppHeader from '../storeComp/mainContent/header/header';
import { MainWrapper } from '../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { LeftWrap, SearchPos } from './../transactions/style';
import { InAppHeader } from '../storeComp/mainContent/styles';
import * as Icon from 'react-feather';
import { CardListView, Card } from './style';
import { useNavigate } from 'react-router';
import { getRequest } from 'utils/request';
import { HeaderFlex, MobileButton } from '../invoice/mainInvoice/style';
import { Link } from 'react-router-dom';
import { SearchArea } from '../orderComp/mainContent/sectionOne/styles';
import WaitLoader from '../actual-components/WaitLoader';
import Helper from 'utils/helper';
import Store from 'utils/store';

const CardList = () => {
  const [cards, setCards] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!Store.get('cards') || Store.get('cards').length === 0) {
      getRequest(`payments/cards?paginate=false`)
        .then((resp: any) => {
          setCards(resp);
          Store.create('cards', resp);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setCards(Store.get('cards'));
      setLoading(false);
    }
  }, []);

  const handleSearch = (text: String) => {
    const storeCards = Store.get('cards');
    if (storeCards) {
      const matches = storeCards.filter(
        x =>
          x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1 ||
          x.issuer.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1,
      );
      setCards(matches);
    }
  };

  return (
    <MainWrapper className="no-scrollbar" mobileTop="4rem" onClick={() => {}}>
      <AppHeader showBack={true} pageName={`Card`} />
      <InAppHeader
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        mobileTop="-1rem"
      >
        <h3 style={{ width: '100%' }}>Cards</h3>
        <p>Manage all your virtual cards</p>
      </InAppHeader>
      <SearchPos hideMobile={true}>
        <button
          style={{
            margin: '0 1rem 0 0',
            color: '#fff',
            padding: '0.55rem 2rem',
            background: '#191919',
            borderRadius: '4px',
            fontSize: '0.8rem',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            navigate('/request-card');
          }}
        >
          <Icon.Plus size={15} style={{ margin: '0 0.5rem 0 0' }} />
          New card
        </button>
        <SearchArea hideMobile={true}>
          <i>
            <Icon.Search />
          </i>
          <input
            type="text"
            placeholder="Search your cards list"
            onChange={e => handleSearch(e.target.value)}
          />
        </SearchArea>
      </SearchPos>
      <BreadcrumbArea>
        <HeaderFlex>
          <LeftWrap
            margin="0rem 0 0 0"
            style={{
              justifyContent: 'space-between',
            }}
          >
            <Link to="/request-card">
              <MobileButton
                onClick={() => {
                  navigate('/request-card');
                }}
              >
                <Icon.Plus size={15} style={{ margin: '0 0.5rem 0 0' }} />
                Create new card
              </MobileButton>
            </Link>
          </LeftWrap>
        </HeaderFlex>
      </BreadcrumbArea>
      <CardListView>
        {cards.length > 0 ? (
          cards.map((card: any, index: number) => (
            <Card
              key={index}
              onClick={() => {
                Store.create('activeCard', card.id);
                navigate('/view-card');
              }}
            >
              <p className="masked">
                <span>{Helper.textSeparator(card.card_number)}</span>
              </p>
              <div>
                <p className="name">{card.name}</p>
                <img
                  src={card.issuer === 'VISA' ? '/visa.png' : '/mastercard.png'}
                  alt="card-logo"
                />
              </div>
            </Card>
          ))
        ) : (
          <p
            style={{
              position: 'absolute',
              top: 'calc(50% - 20px)',
              textAlign: 'center',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading ? (
              <WaitLoader size={48} count={3} />
            ) : (
              'No card found for user. Click the create card button at the top right corner to get started.'
            )}
          </p>
        )}
      </CardListView>
    </MainWrapper>
  );
};

export default CardList;
