import styled, { keyframes } from 'styled-components';
import ImageAd from '../../../../img/ADSrectangle.png';

interface Props {
  customBg: any;
}

interface Props2 {
  customColor: string;
}

export const MainArea = styled.div`
  position: relative;
`;

export const MainFlex = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 0 0 0;
  gap: 5px;

  @media (max-width: 1028px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const LeftWidget = styled.div`
  width: 73%;
  background: #fff;
  margin: 0rem 0 0 0;
  padding: 1rem 2%;
  border-radius: 10px;

  @media (max-width: 1028px) {
    width: 100%;
    background: #fff;
  }
`;

export const HeadFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightWidget = styled.div`
  width: 30%;
  overflow: auto;
  margin: 0rem 0 0 0.5rem;

  @media (max-width: 1028px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

interface RightCardProps {
  padding?: string;
}

export const RightCards = styled.div<RightCardProps>`
  // box-shadow: 0 0 4px #cbced1;
  background: #fff;
  border: 0.5px solid #f2f2f2;
  border-radius: 10px;
  padding: ${p => p.padding || '0 0% 7% 0%'};
  margin: 0 0 3.1rem 0;

  @media (max-width: 1028px) {
    margin: 0 0 0 0;
    position: relative;
    top: 0rem;
    border: none;
  }
`;

export const GreetingWrap = styled.div`
  width: 50%;
  margin: 0rem 0 0 0;

  > h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #191919;
  }

  @media (max-width: 480px) {
    // display: none;
  }
`;

export const PageLinkWidget = styled.div`
  width: 100%;
  display: flex;
  align-items: Center;
  justify-content: flex-end;
  background: #ffffff;
  border-radius: 10px;
  padding: 0.9rem 2%;
  margin: 0 0 1rem 0;

  > div {
    width: 100%;
  }

  @media (max-width: 1028px) {
    width: 100%;
    margin: 2rem 0 0 0;

    > div {
      width: 100%;
    }
  }
`;

export const PageLink = styled.p`
  background: #ffffff;
  color: #000000;
  padding: 1% 2%;
  width: 55%;
  font-size: 0.9rem;
  cursor: pointer;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    opacity: 0.7;
  }

  @media (max-width: 728px) {
    width: 11rem;
  }
`;

export const PageBtn = styled.button`
  background: #000000;
  color: #ffffff;
  border-radius: 2px;
  padding: 2% 5%;
  width: 100%;
  margin: 1rem 0 1rem 0;
  font-size: 0.8rem;
  border: 1px solid #000000;

  :hover {
    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;
  }
`;

interface CardFlexProps {
  display?: string;
  padding?: string;
}
export const CardFlex = styled.div<CardFlexProps>`
  width: 100%;
  overflow: auto;

  > div {
    padding: ${p => p.padding || '2rem 0.3rem'};
    background: #f6f6f6;
    // border: 0.1px solid #d6d6d6;
    border-radius: 10px;
    margin-top: 1rem;
    display: ${p => p.display || 'block'};
    gap: 30px;

    @media (max-width: 728px) {
      background: #fff;
      display: block;
      padding: 2rem 0rem;
    }
  }
`;

export const InfoCard = styled.div<Props>`
  width: 90%;
  padding: 1rem;
  border-radius: 8px;
  position: relative;
  margin: 0 auto 1rem auto;
  height: 14rem;
  box-shadow: 0 0 1.5px #cbced1;
  background: #fff;
  cursor: pointer;

  :hover {
    opacity: 0.7;
    color: #000000;
  }

  @media (max-width: 1028px) {
    width: 95%;
    margin: 0 20px 1rem 0;

    :hover {
      background: #ffffff;
      color: #000000;
    }

    :hover h2 {
      color: #000000;
    }

    :hover h1 {
      color: #000000;
    }
  }

  // p {
  //   color: #8a8a8a;
  // }
`;

export const InfoText = styled.h2<Props2>`
  color: ${p => p.customColor || 'black'};
  font-size: 0.7rem;
  font-weight: bold;
`;

export const ActiveBar = styled.p`
  background: rgba(51, 163, 41, 0.1);
  color: rgba(51, 163, 41);
  padding: 1% 2%;
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 5px;
`;

interface Props3 {
  textAlign?: any;
  justify?: any;
  align?: any;
  dir?: any;
}

export const ToggleBalanceWrap = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  gap: 10px;
  // position: absolute;
  // top: 50%;
  // transform: translate(0, -50%);
  right: 2%;
  color: #ababab;

  > h3 {
    font-weight: 400;
    font-size: 8px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: right;
    color: #8a8a8a;
  }
`;

export const BtnIconWrap = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: Center;
  background: #ffffff;
  border: 0.5px solid #191919;
  color: #c82b38;
`;

export const GenericInfo = styled.div<Props3>`
  background: #f9f9f9;
  width: 90%;
  border-radius: 5px;
  padding: 2% 1rem;
  text-align: ${p => p.textAlign || 'Center'};
  font-size: 0.8rem;
  display: flex;
  justify-content: ${p => p.justify || 'center'};
  align-items: ${p => p.align || 'center'};
  flex-direction: ${p => p.dir || 'row'};
  gap: 5px;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;

  > div {
    margin: 0.3rem 0 0 0;
    color: #191919;

    > p {
      font-weight: 500;
    }

    > h3 {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      letter-spacing: -0.02em;
      color: #8a8a8a;
    }
  }

  > span {
    font-weight: bolder;
  }

  > i {
    margin-left: 5%;
  }
`;

interface IconWrap {
  customIconBg: any;
  customIconColor: any;
}

export const FloatIcon = styled.i<Props2>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${p => p.customColor || '#757575'};
`;

export const InfoIconWrap = styled.div<IconWrap>`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  margin: 0rem 0 1rem 0;
  background: ${p => p.customIconBg || '#d7d7d7'};

  i {
    color: ${p => p.customIconColor || '#000000'};
  }
`;

export const SubHeaderWrap = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;

  h3 {
    color: #000000;
    font-weight: 500;
    color: #191919;
    padding: 0 7%;
    font-size: 0.8rem;
  }

  p {
    padding: 0 7%;
    font-size: 0.7rem;
    color: #191919;
  }
`;

export const HistoryWidget = styled.div`
  height: 30rem;
  overflow: auto;

  @media (max-width: 728px) {
    height: auto;
    max-height: auto;
  }
`;

export const HistoryItemCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 7% 0.4rem 7%;
  margin: 1rem 0;
  border-top: 0.5px solid #f2f2f2;
  position: relative;

  @media (max-width: 1028px) {
    width: 95%;
    padding: 1rem 0% 0.4rem 0%;
    border-top: none;
    border-top: 0.5px solid #f2f2f2;
  }
`;

export const HistoryInfoSec = styled.div`
  width: 25%;
  text-align: right;

  > h4 {
    font-size: 0.7rem;
    color: #757575;
  }
`;

interface PropsArgs {
  customBg: any;
}

export const HistoryItemIconWrap = styled.div<PropsArgs>`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  background: ${p => p.customBg};
  align-items: Center;
  border-radius: 10rem;
`;

export const HistoryItemImage = styled.img`
  width: 1.2rem;
  height: 1.2rem;
`;

export const HistoryTextWrap = styled.div`
  width: 45%;

  h4 {
    font-size: 0.8rem;
    font-weight: 500;
    color: #191919;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    font-size: 0.7rem;
    color: #757575;
  }

  @media (max-width: 1028px) {
    width: 45%;
  }
`;

export const HistoryAmount = styled.p`
  font-size: 0.8rem;
  width: 100%;
`;

export const ChartEmptyState = styled.div`
  position: absolute;
  width: 100%;
  bottom: 1rem;
`;

export const ChartWidget = styled.div`
  width: 100%;
  padding: 0% 0%;
  background: #f2f2f2;
  margin: 2rem 0 0 0;
  border-radius: 8px;
  position: Relative;

  @media (max-width: 1028px) {
    display: none;
  }
`;

export const BelowCardWrappers = styled.div`
  margin: -1.7rem 0 0rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: no-wrap;
  gap: 20px;

  > div {
    width: 40%;
    padding: 5% 2%;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    height: 10.5rem;

    @media (max-width: 1028px) {
      width: 100%;
      margin: 0 0 0.5rem 0;
      height: 8rem;
      padding: 1rem 2rem;
    }
  }

  @media (max-width: 1028px) {
    flex-wrap: wrap;
  }
`;

export const CopyFlex = styled.div`
  display: flex;
  align-items: Center;
  justify-content: flex-end;
  margin: 0 0 0rem 0;

  @media (max-width: 1028px) {
    margin: 0;
  }
`;

export const CopyIconsWrap = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 10rem;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 2%;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const DaysDisplay = styled.div`
  width: inherit;
  padding: 2% 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileShow = styled.div`
  display: none;

  @media (max-width: 1028px) {
    display: block;
    margin: 4rem 0 0 0;
  }
`;

export const DesktopShow = styled.div`
  display: block;
  margin: 4rem 0;

  @media (max-width: 728px) {
    margin: 4rem 0 1rem 0;
  }
`;
export const BalanceFlag = styled.div`
  display: flex;
  align-items: Center;
  gap: 5px;

  > p {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #474747;
  }
`;
export const QuickActionWrap = styled.div`
  margin: 2rem 0 2rem 0;

  > h2 {
    font-weight: 500;
    color: #191919;
    margin: 0rem 0 2rem 0;
  }
`;

export const QuickActionItemWrap = styled.div`
  margin: 2rem 0;
  padding: 2% 0;
  background: #f6f6f6;
  // border: 0.1px solid #d6d6d6;
  border-radius: 10px;

  @media (max-width: 1028px) {
    width: 100%;
    background: #fff;
  }
`;

export const QuickActionItemCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: Center;
  gap: 15px;

  @media (max-width: 1028px) {
    margin: 1rem 0;
  }

  p {
    font-size: 0.7rem;
    font-weight: 500;
  }
`;

export const QuickItemIcon = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  border-radius: 10rem;
  background: #ffffff;
  border: 0.5px solid #dadada;
  margin: 0 0 1rem 0;

  > i {
    font-size: 1.5rem;
    color: #191919;
  }

  :hover {
    box-shadow: 0px 0px 2px #191919;
  }
`;

export const InfoNumber = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: Center;
  justify-content: space-between;

  > h1 {
    font-weight: 500;
    font-size: 1.4rem;
    color: #191919;
  }
`;

export const ShortCard = styled.section`
  width: 20%;
  padding: 3.7% 3.5%;
  margin: 0 3% 1rem 0;
  background: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('./Rectangle7.png');
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
  color: #000000;
  border-radius: 8px;
  height: 10.5rem;

  :hover {
    box-shadow: 0px 0px 20px grey;
  }

  @media (max-width: 1028px) {
    width: 100%;
    padding: 15% 2rem;
    margin: 1rem 2% 2rem 2%;
  }
`;

export const ToggleWrap = styled.div`
  display: flex;
  // align-items:center;
`;

export const ToggleButton = styled.div`
  position: relative;
  display: inline-block;
  width: 41px;
  height: 24px;
  top: -0.2rem;
`;

export const ToggleSpan = styled.span<{ checked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  border-radius: 34px;
  background-color: ${props => (props.checked ? '#c82b38' : '#ccc')};

  &:focus {
    box-shadow: 0 0 1px #33a329;
  }

  &:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 0px;
    bottom: 50%;
    background-color: #fff;
    border-radius: 50%;
    transform: ${props =>
      props.checked ? 'translate(18px, 50%)' : 'translate(0px, 50%)'};
    transition: 0.4s;
  }
`;

export const EmojiIconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 2rem 0 0 0;

  > img {
    width: 1.5rem;
  }
`;

interface DisclaimerSectionProps {
  mobileShow?: boolean;
}

export const DisclaimerSection = styled.div<DisclaimerSectionProps>`
  display: ${p => (p.mobileShow ? 'none' : 'flex')};
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  margin: 0rem 0 3rem 0;
  background: #fff;
  padding: 1rem 2% 3rem 2%;
  border-radius: 0 0 10px 10px;

  > img {
    width: 2rem;
  }

  > p {
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #757575;
  }

  @media (max-width: 728px) {
    display: ${p => (p.mobileShow ? 'flex' : 'none')};
    width: 100%;
    margin: 0.5rem 0 0 0;
    gap: 10px;
    background: #fff;
  }
`;

export const ArtistCard = styled.div`
  width: 14rem;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  /* box-shadow: 0 0 1.5px #cbced1; */
  background: #fff;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 728px) {
    width: 17rem;
    padding: 30px 85px 30px 0;
  }
`;

interface PropsArgss {
  customBg: string;
}

export const WrapperArtistCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 728px) {
    margin-left: 0;
    gap: 20px;
  }
`;

export const Logohandler = styled.div<PropsArgss>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${p => p.customBg};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Texthandler = styled.div`
  /* border: 1px solid black; */
  h3 {
    font-size: 20px;
    color: #161616;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    color: #474747;
  }
`;

const Animation = keyframes`
  from {
    transform: scale(1);
  } to{ 
    transform:scale(1.04);
  }
`;

const AnimationOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const QuickActionItems = styled.div`
  width: auto;
  padding: 0.5rem;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  overflow: scroll;

  @media (max-width: 728px) {
    display: flex;
    gap: 20px;
    margin-left: 0;
    margin-right: 0;
    padding: 0.5rem 0;
  }
`;

export const QuickActionItemsCard = styled.div<PropsArgss>`
  width: 160px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 10px;
  border-radius: 10px;
  background-image: ${p => p.customBg};
  transform: scale(1);
  transition: all 0.3s ease;
  animation: ${AnimationOut} 0.5s forward;

  &:hover {
    animation: ${Animation} 0.3s forwards;
  }
  /* background-image: linear-gradient(#ffffff, #ff9800); */

  @media (max-width: 728px) {
    width: 250px;
    padding: 10px 50px 10px 10px;
  }
`;

export const QuickItemLogo = styled.div<PropsArgss>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${p => p.customBg || ''});
  /* background-size: auto; */
  /* background-position: center; */
  /* background-repeat: no-repeat;
  background-position-x: -19px;
  background-position-y: -31px;
  background-size: 200px; */

  img {
    width: 50px;
    height: 50px;
    border: 1px solid black;
  }
`;

export const QuicktextWrapper = styled.div`
  width: 90%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 3px;

  @media (max-width: 728px) {
    width: 100px;
  }

  h3 {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
  }

  p {
    font-size: 10px;
    color: #fff;
    font-weight: 900;
    line-height: 10px;
    cursor: pointer;
  }
`;

export const AdPart = styled.div`
  width: auto;
  height: 10rem;
  border-radius: 10px;
`;

export const BackGroundImageSlideShow = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-image: url(${ImageAd});
  background-position: center;
  background-size: cover;
  padding: 10px;
  padding-left: 20px;
  color: white;

  p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
`;
